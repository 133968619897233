<route>
{
  "meta": {
    "permission": [
      "settings.view_companycontact"
    ]
  }
}
</route>

<template>
  <div>
    <dialcontacts
      v-model="dialog"
      :to-edit="toEdit"
      :company="value"
      @done="reload = true"
    />
    <i-table
      :title="$tc('contact', 2)"
      :headers="headers"
      app="settings.companycontact"
      api="company.contact"
      :reload.sync="reload"
      :opt="{ company: value, mode }"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import dialcontacts from '../contact_settings/modal.vue'
export default {
  components: {
    dialcontacts
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('contact', 1),
          value: 'contact'
        },
        {
          text: this.$t('type'),
          value: 'type_name'
        },
        {
          text: this.$t('action'),
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
