<route>
{
  "meta": {
    "permission": [
      "setting.view_company",
      "settings.change_company"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form
          enctype="multipart/form-data"
          @submit.prevent="handleSubmit(submit)"
          @reset.prevent="reset"
        >
          <i-toolbar
            :title="$tc('company_settings', 1)"
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <v-row class="my-0">
              <!-- Imagen de la empresa -->
              <v-col class="align-center justify-center" cols="12" md="6">
                <i-image
                  :value="imageUrl"
                  :file.sync="company.company_photo"
                  v-model="imageUrl"
                />
              </v-col>

              <!-- Datos de la empresa -->
              <v-col cols="12" md="6">
                <v-row class="my-0">
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="name"
                      :name="$t('name')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="company.name"
                        outlined
                        validate-on-blur
                        name="name"
                        :error-messages="errors[0]"
                        :label="$t('name')"
                        class="secondary--text"
                        key="name-input"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="address"
                      :name="$t('address')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        v-model="company.address"
                        name="address"
                        rows="1"
                        outlined
                        :error-messages="errors[0]"
                        :label="$t('address')"
                        class="secondary--text"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="city"
                      :name="$t('city')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="company.city"
                        name="city"
                        outlined
                        :error-messages="errors[0]"
                        :label="$t('city')"
                        class="secondary--text"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="state"
                      :name="$t('state')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="company.state"
                        name="state"
                        :error-messages="errors[0]"
                        :label="$t('state')"
                        class="secondary--text"
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="zip_code"
                      :name="$t('zip_code')"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="company.zip_code"
                        name="zip_code"
                        :error-messages="errors[0]"
                        :label="$t('zip_code')"
                        class="secondary--text"
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      vid="country"
                      :name="$t('country')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        autocomplete="off"
                        v-model="company.country_id"
                        name="country"
                        :error-messages="errors[0]"
                        :label="$t('country')"
                        class="secondary--text"
                        :items="country_list"
                        item-text="name"
                        item-value="pk"
                        outlined
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-tabs
              color="primary"
              v-model="activeTab"
              next-icon="fa-chevron-right"
              prev-icon="fa-chevron-left"
              centered
              show-arrows
              :icons-and-text="$vuetify.breakpoint.smAndUp"
            >
              <v-tab v-for="tab in tabs" :key="tab.id" :href="tab.route">
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="mt-2"
                  v-text="$tc(tab.name, 2)"
                ></span>
                <v-icon
                  :medium="$vuetify.breakpoint.smAndUp"
                  v-text="tab.icon"
                />
              </v-tab>
              <v-tabs-items v-model="activeTab">
                <!-- Formatos de moneda y fecha -->
                <v-tab-item value="tab-format" class="pt-3">
                  <v-row class="my-0">
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        vid="currency"
                        :name="$t('currency')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          autocomplete="off"
                          v-model="company.currency_id"
                          name="currency"
                          :error-messages="errors[0]"
                          :label="$tc('currency', 1)"
                          class="secondary--text"
                          :items="currency_list()"
                          :item-text="getCurrencyName"
                          item-value="pk"
                          outlined
                        ></v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        vid="formatDate"
                        :name="$t('format_date')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          v-model="company.format_date"
                          :items="['mm-dd-yyyy', 'dd-mm-yyyy', 'yyyy-mm-dd']"
                          :error-messages="errors[0]"
                          name="formatDate"
                          autocomplete="off"
                          :label="$t('format_date')"
                          outlined
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        vid="web_url"
                        :name="$t('web_url')"
                        rules="url"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="company.web_url"
                          name="web_url"
                          outlined
                          :error-messages="errors[0]"
                          :label="$t('web_url')"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Impuestos -->
                <v-tab-item value="tab-taxes" class="p-3">
                  <taxes />
                </v-tab-item>

                <!-- Contactos -->
                <v-tab-item value="tab-contact" class="p-3">
                  <contacts mode="company" v-model="company.pk" />
                </v-tab-item>

                <!-- Tablero -->
                <v-tab-item value="tab-dashboard" class="pt-3">
                  <h2 class="title text-center mt-3">
                    {{ $t('dashboard_default') }}
                  </h2>
                  <v-row class="my-0 justify-content-center">
                    <v-col
                      class="flex-col-centered"
                      cols="12"
                      sm="4"
                      md="3"
                      lg="2"
                    >
                      <v-switch
                        id="checkbox-dashboard"
                        class="v-switch"
                        color="secondary"
                        hide-details
                        value="tab-dashboard"
                        :label="$tc('dashboard', true)"
                        v-model="company.dashboard"
                      ></v-switch>
                      <label for="checkbox-dashboard">
                        <v-img
                          class="mx-auto"
                          :src="
                            require('@/assets/color-icons/dashboard/dashboard.png')
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </label>
                    </v-col>
                    <v-col
                      class="flex-col-centered"
                      cols="12"
                      sm="4"
                      md="3"
                      lg="2"
                    >
                      <v-switch
                        id="checkbox-list"
                        class="v-switch"
                        color="secondary"
                        hide-details
                        value="tab-list"
                        :label="$tc('list', false)"
                        v-model="company.dashboard"
                      ></v-switch>
                      <label for="checkbox-list">
                        <v-img
                          class="mx-auto"
                          :src="
                            require('@/assets/color-icons/dashboard/list.png')
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </label>
                    </v-col>
                    <v-col
                      class="flex-col-centered"
                      cols="12"
                      sm="4"
                      md="3"
                      lg="2"
                    >
                      <v-switch
                        id="checkbox-calendar"
                        class="v-switch"
                        color="secondary"
                        hide-details
                        value="tab-calendar"
                        :label="$tc('calendar', false)"
                        v-model="company.dashboard"
                      ></v-switch>
                      <label for="checkbox-calendar">
                        <v-img
                          class="mx-auto"
                          :src="
                            require('@/assets/color-icons/dashboard/calendar.png')
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </label>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Colores -->
                <v-tab-item value="tab-colors" class="py-3">
                  <v-row class="my-0 justify-content-center">
                    <v-col cols="12" md="8" lg="6">
                      <h6 class="title grey--text font-weight-black">
                        {{ $tc('dark_mode') }}
                      </h6>
                      <v-switch
                        class="v-switch"
                        color="secondary"
                        name="dark"
                        :label="$t('active')"
                        v-model="company.dark"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 justify-content-center">
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <h6 class="title grey--text font-weight-black">
                        {{ $t('primary_color') }}
                      </h6>
                      <v-color-picker
                        elevation="1"
                        mode="hexa"
                        canvas-height="120"
                        dot-size="18"
                        v-model="company.primary_color"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3">
                      <h6 class="title grey--text font-weight-black">
                        {{ $t('secondary_color') }}
                      </h6>
                      <v-color-picker
                        elevation="1"
                        mode="hexa"
                        canvas-height="120"
                        dot-size="18"
                        v-model="company.secondary_color"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import taxes from '../../components/tax/list.vue'
import contacts from '../../components/contact_settings/list.vue'

export default {
  components: {
    taxes,
    contacts
  },
  data() {
    return {
      mode: 1,
      load: false,
      first_time: false,
      country_list: [],
      imageUrl: null,
      activeTab: 'tab-format',
      tabs: [
        {
          id: 1,
          name: 'format',
          icon: 'fa-building',
          route: '#tab-format'
        },
        {
          id: 2,
          name: 'globaltax',
          icon: 'fa-money-check',
          route: '#tab-taxes'
        },
        {
          id: 3,
          name: 'contact',
          icon: 'fa-address-book',
          route: '#tab-contact'
        },
        {
          id: 4,
          name: 'dashboard',
          icon: 'fa-tachometer-alt',
          route: '#tab-dashboard'
        },
        {
          id: 5,
          name: 'color',
          icon: 'fa-palette',
          route: '#tab-colors'
        }
      ],
      company: {
        company_photo: null,
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country: null,
        country_id: null,
        web_url: '',
        format_date: 'mm-dd-yyyy',
        currency: null,
        currency_id: null,
        primary_color: '',
        secondary_color: '',
        dark: true,
        dashboard: ['tab-dashboard', 'tab-list', 'tab-calendar']
      }
    }
  },
  computed: {
    ...mapGetters({
      currency_list: 'company/getCurrencies',
      myCompanies: 'session/myCompanies'
    })
  },
  watch: {
    'company.dashboard': {
      handler(val) {
        if (val == null) {
          this.company.dashboard = 'tab-dashboard'
        }
      }
    }
  },
  methods: {
    getCurrencyName: (val) => {
      return `${val.name} (${val.code}) `
    },
    async submit() {
      this.load = true
      let form_data = new FormData()
      for (var key in this.company) {
        if (this.company[key] == null) {
          continue
        }
        if (key == 'users') {
          form_data.append(key, JSON.stringify(this.company[key]))
        } else {
          form_data.append(key, this.company[key])
        }
        if (
          key == 'company_photo' &&
          (this.company.company_photo == null ||
            String(this.imageUrl).indexOf('http') != -1 ||
            String(this.company.company_photo).indexOf(';base64') != -1 ||
            String(this.company.company_photo).indexOf('http') != -1 ||
            String(this.imageUrl).indexOf('/sources/') != -1)
        ) {
          form_data.delete('company_photo')
        }
      }
      const company = await this.$api.company.edit({
        pk: this.company.pk,
        form: form_data
      })
      this.$store.commit('company/companyData', company.data)
      this.$toast.success(`${this.$tc('company', 1)} ${this.$tc('edited', 1)}`)
      this.load = false
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.country_list = country.data
    },
    async getSettingInfo() {
      this.loading = true
      const company = await this.$api.company.show({
        pk: this.myCompanies[0].id
      })
      this.company = company.data
      this.imageUrl = this.company.company_photo
      this.loading = false
    }
  },
  mounted() {
    this.getCountries()
    this.getSettingInfo()
  }
}
</script>
<style lang="sass">
.flex-col-centered
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  label:hover
    cursor: pointer
</style>
