<route>
{
  "meta": {
    "permission": [
      "base_config.view_globaltax"
    ]
  }
}
</route>

<template>
  <div>
    <dialtax
      v-model="dialog"
      :to-edit="toEdit"
      :company="company.pk"
      @done="reload = true"
    />
    <i-table
      :title="$tc('globaltax', 2)"
      :headers="headers"
      app="base_config.globaltax"
      api="tax"
      :reload.sync="reload"
      :opt="{ company: company.pk }"
      @click:create="open()"
      @click:edit="open($event)"
    >
      <template v-slot:item.percent="{ value }"> {{ value }}% </template>
    </i-table>
  </div>
</template>
<script>
import tax from './modal.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    dialtax: tax
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    }),
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('percent'),
          value: 'percent'
        },
        {
          text: this.$t('action'),
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
