<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('contact', 2) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0">
      <v-col cols="12">
        <ValidationProvider
          vid="contact"
          :name="$tc('contact', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            name="contact"
            :error-messages="errors[0]"
            v-model="contact.contact"
            outlined
            color="secondary"
            :label="$tc('contact', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="typecontact"
          :name="$tc('typecontact', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            v-model="contact.type_contact"
            name="typecontact"
            autocomplete="off"
            :error-messages="errors[0]"
            :items="contact_types"
            :loading="isLoading"
            clearable
            outlined
            :search-input.sync="searchContact"
            class="secondary--text"
            item-text="name"
            item-value="pk"
            :label="$tc('typecontact', 1)"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    company: {
      type: Number,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      contact: {
        contact: '',
        type_contact: null,
        company: null
      },
      edit: false,
      contact_types: [],
      searchContact: ''
    }
  },
  watch: {
    searchContact(val) {
      if (val == null || (typeof val === 'string' && val.length % 2 == 0)) {
        this.getContactType()
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchContact = ''
          this.getContactType()
          this.edit = this.toEdit !== null
          this.contact =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { contact: '', type_contact: null, company: this.company }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          !this.edit
            ? await this.$api.company.contact.create({ form: this.contact })
            : await this.$api.company.contact.edit({
                pk: this.contact.pk,
                form: this.contact
              })
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('contact', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    },
    getContactType() {
      this.isLoading = true
      this.$api.company.contact.type
        .list({ opt: { params: { search: this.searchContact, size: 8 } } })
        .then((res) => {
          this.contact_types = res.data.results
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
